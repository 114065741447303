import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    minHeight: '600px',
    textAlign: 'center'
  },
  title: {
    color: '#fff',
    paddingBottom: theme.spacing(3),
  }
}));

export default () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.root}>
        <h3 className={classes.title}>Sorry, Page not Found.</h3>
      </div>
    </Container>
  );
};