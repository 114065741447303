import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Routes from "./Routes";

const useStyles = makeStyles( () => ({
  root: {
    backgroundColor: '#222',
    minHeight: '600px',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Routes />
    </div>
  );
}

