import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    minHeight: '600px'
  },
  alert: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
  <div className={classes.root}>
    <Container>
    <Alert
        variant="outlined"
        severity={props.message.type}
        className={ classes.alert }
      >
        <span dangerouslySetInnerHTML={{ __html: props.message.description }}></span>
      </Alert>
    </Container>
  </div>
  );
};
