import { Fetch } from './FetchApi';
import Config from '../Config';

export const GetUrl = (id) => {
  return `${Config.CONFIG_URL_PREFIX}${id}${Config.CONFIG_URL_POSTFIX}?v=${new Date().getTime()}`;
}

export const GetDefaultVideo = (config) => {
  // console.log('GetDefaultVideo', config);
  if (config && config.videos) {
    for (let i = 0; i < config.videos.length; i++) {
      if (config.defaultVideo === config.videos[i].id) {
        return config.videos[i];
      }
    }
  }
};

export const FetchConfig = async (url) => {
  const config = await FetchUrl(url);
  return config;
}

const FetchUrl = async (url) => {
  //console.log('Fetch Url: ', url);
  const payload = {
    method: 'GET',
  }
  return await Fetch(url, payload);
};
