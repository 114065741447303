import React from "react";
import { Route, Switch } from "react-router-dom";

import Player from "./containers/Player";
import NotFound from "./containers/NotFound";

export default function Routes() {
  return (
    <Switch>
      <Route path="/:id" exact component={Player} />
      <Route component={NotFound} />
    </Switch>
  );
}