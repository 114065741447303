import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";

import { useInterval } from '../hooks/useInterval';
import { GetUrl, FetchConfig, GetDefaultVideo } from '../helpers/ConfigApi';

import { makeStyles } from '@material-ui/core/styles';

import VideoPlayer from '../components/video/VideoPlayerJS';
import Loading from './Loading';

const useStyles = makeStyles( () => ({
  root: {
  },
}));

export default (props) => {
  const { id } = useParams();
  const [isInitialising, setIsInitialising] = useState(true);
  const [message, setMessage] = useState({ type: 'info', description: "Loading ... " });
  const [url, setUrl] = useState(GetUrl(id));
  const [config, setConfig] = useState();
  const defaultVideo = useMemo(() => GetDefaultVideo(config), [config]);

  useEffect(() => {
    if (url) {
      FetchConfig(url).then((newConfig) => {
        if (newConfig) {
          setConfig(newConfig);
          setIsInitialising(false);
        }
      }).catch(e => { console.log(e); setMessage({ type: 'error', description: e.message }); });
    }
  }, [url]);

  useInterval(() => {
    setUrl(GetUrl(id));
  }, 60000);  

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {(isInitialising || !defaultVideo) && (
        <Loading message={message} />
      )}
      {!isInitialising && defaultVideo && (
        <VideoPlayer src={defaultVideo} userId='' />
      )}
    </div>
  );
}
